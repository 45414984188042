<template>
    <div>
        <app-paginated-table ref="invoiceList" :fields="fields" :items="items" :mobileHeaders="['attachment','invoiceNo','amount', 'actions']" :current-page="current_page" :total-pages="total_pages" :no-filters="true" :total="total_records" :placeRight="2" :loading="loading" @onPageChange="onPageChange" @onLoadMore="loadMoreInvoices">
            <template #header>
                <div class="row">
                    <div class="col-md-4 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="searchInvoices()" class="m-8-24"></app-text-input>
                    </div>
                </div>
            </template>
            <template #filters>
                <div class="row">9
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="searchInvoices()"></app-text-input>
                    </div>
                </div>
            </template>
            <template #head(actions)>
                {{ '' }}
                <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                /> -->
            </template>
            <template #cell(customers)="data">
                <b-avatar-group size="32px">
                    <b-avatar
                    :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                    :text="c.email.slice(0,1).toUpperCase()"
                    :variant="colors[index]"
                    v-b-tooltip.hover.v-primary="c.email"
                  />
                  <b-avatar v-if="data.item.collaborators.length > 1" 
                    class="mx-0" 
                    :text="`+${data.item.collaborators.length-1}`"
                    variant="light-success"
                    id="tooltip-avatar"
                    v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"
                    />
                    <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in data.item.collaborators.slice(1)" :key="index">{{c.email}}<br></span></b-tooltip> -->
                </b-avatar-group>
            </template>
            <template #cell(due_date)="data">
                {{ getFormatedDate(data.item.dueDate,"DD MMM YYYY") }}
            </template>
            <template #cell(attachment)="data">
                <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
            </template>
            <template #cell(amount)="data">
                {{ data.item.amount | currency}}
            </template>
            <template #cell(support_tickect)="data">
                {{ data.item.support_tickect?data.item.support_tickect:'N/A' }}
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Block Level Dropdown Menu"
                right
                variant="none"
                menu-class="w-100"
                size="sm"
                no-caret
                >
                <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                    />
                </template>
                <b-dropdown-item @click="downloadInvoice(data.item._id)">Download</b-dropdown-item>
                </b-dropdown>
                
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.status=='sent'? 'light-secondary':data.item.status=='refunded'?'light-danger':data.item.status=='paid'?'light-primary':data.item.status=='processing_payment'?'light-warning':'light-info'">
                    {{ data.item.status=='sent'? 'Pending':toCapitalize(data.item.status) }}
                </b-badge>
            </template>
            <template>
            </template>
        </app-paginated-table>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem,BAvatarGroup, BAvatar, BBadge,VBTooltip, BTooltip} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import {get_invoices, download_invoice} from '@/apis/admin/invoices'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
export default{
    directives:{
        'b-tooltip':VBTooltip,
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        AppSelectBox,
        BAvatarGroup,
        BAvatar,
        BBadge,
        BTooltip
    },
    data(){
        return{
            search:'',
            fields:[
                {
                    key:'invoiceNo',
                    label:'Invoice #'
                },
                {
                    key:'description',
                    label:'Description'
                },
                {
                    key:'amount',
                    label:'Amount'
                },
                {
                    key:'status',
                    label:'Status'
                },
                {
                    key:'customers',
                    label:'Customers',
                },
                {
                    key:'due_date',
                    label:'Due Date'
                },
                {
                    key:'attachment',
                    label:'Attachment'
                },
                {
                    key:'actions'
                }
            ],
            items: [
            ],
            filters:{
                page:1,
                limit:10,
                search:'',
                status:''
            },
            current_page:1,
            total_records:0,
            total_pages:0,
            status:'',
            loading:false,
            options: [
                { value: '', text: 'Status' },
                { value: 'sent', text: 'Sent' },
                { value: 'paid', text: 'Paid' },
                { value: 'pending', text: 'Pending' },
            ],
            showSidebar:false,
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loadMore:false,
        }
    },
    mounted(){
        this.getInvoices()
    },
    methods:{
        onPageChange(page){
            this.filters.page=page
            this.getInvoices()
        },
        getInvoices(){
            this.loading=true;
            get_invoices(this.filters).then((resp)=>{
                this.loading=false;
                this.items=this.loadMore?[...this.items,...resp.data.invoices]:resp.data.invoices;
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.invoicesCount
                this.total_pages=resp.data.invoicesCount>0?resp.data.invoicesCount/this.filters.limit:0
                this.loadMore=false
                this.$refs.invoiceList.loadMore=false
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        downloadInvoice(id){
            this.downloadLoading=true;
            download_invoice(id).then(resp=>{
                this.downloadLoading=false;
                this.download(resp.url)
            })
        },
        download(url){
            var link = document.createElement("a");
            // If you don't know the name or want to use
            // the webserver default set name = ''

            link.setAttribute('download', this.$route.params.invoice);
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        },
        loadMoreInvoices(){
            this.filters.page+=1
            this.getInvoices()
            this.loadMore=true
        },
        searchInvoices(){
            this.filters.page=1
            this.getInvoices()
        }

    }
}
</script>
<style scoped>
.m-8-24{
    margin:8px 24px !important
}
</style>